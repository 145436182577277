import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles.js';

import Button from '@material-ui/core/Button';

import { GTM_EVENTS } from 'appConstants';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import NavigationIcon from '@material-ui/icons/Menu';

class Navigation extends React.PureComponent {
  state = {
    mobileNavigationOpen: false,
    anchorEl: null,
  };

  closeMobileNavigation = () => {
    this.setState({ 
      mobileNavigationOpen: false 
    }, () => this.props.fireGtmEvent(GTM_EVENTS.closedMobileNavigation))
  };

  openMobileNavigation = (event) => {
    this.setState({ 
      mobileNavigationOpen: true, anchorEl: event.currentTarget 
    }, () => this.props.fireGtmEvent(GTM_EVENTS.openedMobileNavigation))
  };

  render() {
    const { classes, width, renderHTML, lpData, onNavigationClick, cta, onCtaClick } = this.props;
    const { mobileNavigationOpen } = this.state;

    const mobile = width === 'xs' || width === 's'

    const menuItems = lpData.length <= 1 ? [] : lpData.map(lp => {
      const sectionTitles = lp.sections.filter(s => !!s.heroTitle);
      const defaultTitle = sectionTitles.length > 0 ? renderHTML(sectionTitles[0].heroTitle) : '';
      return lp.navigationLabel || defaultTitle
    });

    if(menuItems.length === 0) return null;

    const desktopNavigation = (
      <header className={classes.navContainer}>
        <div className={classes.stickyNav}>
          <div className="container">
            <nav className={`navbar navbar-expand-md ${classes.navBar}`}>
              <a className="navbar-brand" href="#">
                <img src="https://s3-eu-west-1.amazonaws.com/hibase-static-assets/logo.png" height="44" alt="hibase" />
              </a>

              {mobile &&
                <div className={classes.navigationContainer}>
                  <IconButton
                    className={classes.mobileNavigationMenuButton}
                    aria-owns={mobileNavigationOpen ? 'navigationMenu' : null}
                    aria-haspopup="true"
                    onClick={mobileNavigationOpen ? this.closeMobileNavigation : this.openMobileNavigation}
                  >
                    <NavigationIcon className={classes.mobileNavigationMenuIcon} />
                  </IconButton>
                  <Menu
                    id="navigationMenu"
                    className={classes.mobileNavigationMenuList}
                    anchorEl={this.state.anchorEl}
                    open={mobileNavigationOpen}
                    onClose={this.closeMobileNavigation}
                  >
                    {menuItems.map((menuItem, i) => (
                      <MenuItem 
                        key={`navigationLabel${i}`}
                        onClick={() => {
                          this.closeMobileNavigation();
                          onNavigationClick(i, menuItem);
                        }}>
                        {menuItem}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              }
              {!mobile &&
                <div className="collapse navbar-collapse" id="navbarNav3">
                  <ul className="navbar-nav ml-auto">
                    {menuItems.map((menuItem, i) => {
                      return (
                        <li key={`navigationLabel${i}`} className="nav-item">
                          <a 
                            className={`nav-link ${classes.navLink}`} 
                            href={`/${lpData[i].slug || ""}`} 
                            onClick={(e) => {
                              e.preventDefault();
                              onNavigationClick(i, menuItem)
                            }}
                          >{menuItem}</a>
                        </li>
                      );
                    })}
                  </ul>

                  <Button
                    variant="outlined"
                    className={classes.cta}
                    onClick={() => onCtaClick(null, 'navigation')}>
                    { renderHTML(cta) }
                  </Button>
                </div>
              }
            </nav>
          </div>
        </div>
      </header>
    );

    return desktopNavigation;
  }
}

export default withStyles(styles)(Navigation);
