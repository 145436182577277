const styles = theme => ({
  firstSlide: {
  	paddingTop: '2.5rem',
  },
  container: {
  	backgroundColor: theme.palette.layout.secondaryBackground,
  },
  blocksContainer: {
  	backgroundImage: 'url(https://s3-eu-west-1.amazonaws.com/hibase-static-assets/pictures/ws/bg1.png)',
  },
});

export default styles;
