const styles = theme => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '300px',
    maxWidth: '600px',
    padding: 25,
    width: '80%',
  },
  formContainerMobile: {
    minWidth: '100%',
    maxWidth: '100%',
    width: '100%',
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dialogButton: {
    width: '100%',
    margin: '0px 20px',
  },

  formLegalNotice: {
    padding: '25px 0px 25px 48px',
  },

  formLegalNoticeSubmit: {
    padding: '0px 25px',
    width: '100%',
  },

  formLegalNoticeText: {
    ...theme.typography.caption,
  },

  stepper: {
    minHeight: '48px',
  },

  formDescriptionField: {
    // ...theme.typography.subheading,
    // ...baseCopyStyle,
    ...theme.typography.bodyDefault,
    width: '100%',
    textAlign: 'left',
    marginBottom: 15,
  },

  successMessageCopy: {
    // ...baseCopyStyle,
    ...theme.typography.bodyDefault,
    padding: '0px 25px',
  }
});

export default styles;
