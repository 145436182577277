import crypto from 'crypto';
import { Cookies } from 'react-cookie';

import { getURLParams } from 'utils';

function generateUUID() {
	const uuid = crypto.randomBytes(20).toString('hex');
	return uuid
}

const options = {
  // domain: `.${window.location.host}`,
  // secure: window.location.protocol === "https:",
  path: '/',
};

export default function getLocalCookie() {
  const localCookie = new Cookies();
  if(!localCookie.get('uuid')) {
  	localCookie.set('uuid', generateUUID(), options);
    localCookie.set('td', new Date().getTime().toString(), options); // firstSeen
  	localCookie.set('ur', getURLParams(), options); // urlParams
  	localCookie.set('fc', 'false', options);								         // formCompleted
  }

  return {
  	uuid: localCookie.get('uuid'),
  	firstSeen: localCookie.get('td'),
    urlParams: localCookie.get('ur'),
  	formCompleted: localCookie.get('fc') ? JSON.parse(localCookie.get('fc')) : false,
  }
}

export function setLocalCookieValue(key, value) {
  const localCookie = new Cookies();
  localCookie.set(key, value, options);
}

export function resetLocalCookie() {
  const localCookie = new Cookies();
  localCookie.remove('uuid', options);
  localCookie.remove('td', options);
  localCookie.remove('ur', options);
  localCookie.remove('fc', options);
}