export default {
  "navigationLabel": "Realtime analytics",
  "slug": "realtime-analytics",
  "globals": {
    // "formTitle": "Hi",
    "cta": "Get in touch",
    "formCompletedCta": "Contact us",
    "successMessage": "thank you",
  },

  "sections": [
    {
      "sectionType": "featureHero",
      "img": "https://s3-eu-west-1.amazonaws.com/hibase-static-assets/pictures/ws/tsr5.png",
      "backgroundColor": "#2a373e",
      "title": "Read and write in realtime from your Data Warehouse",
      "subtitle": "Easily configure a JSON API to access your Data warehouse, feed it with realtime data or to use its contents within your website and apps.",
      "cta": "Get in touch now",
      "snippets": [
        {
          "title": "In-house realtime tracking",
          "subtitle": "Have your backend and frontend apps push events directly to your Data Warehouse, for fast and responsive business analytics without the need for third party integrations."
        },
        {
          "title": "Landing pages and surveys",
          "subtitle": "Set your data team free to run tests and surveys. Push new leads and responses straight to your Data Warehouse for quick analysis and reduced complexity."
        },
        {
          "title": "Data as a service",
          "subtitle": "Plug your data and the people behind it into your product by serving content to your users right out of your Data Warehouse."
        },
      ]
    },
    {
      "sectionType": "bottomSeparator",
    },
    {
      "sectionType": "teaser",
      "img": "https://s3-eu-west-1.amazonaws.com/hibase-static-assets/pictures/ws/sc4.png",
      "secondary": true,
      "direction": "ltr",
      "title": "Realtime analytics without the overhead",
      "subtitle": "Let your developers focus on your product and your data team on analytics. Issue new endpoints on the fly and ensure BI never lags behind new features.",
      "cta": "Get in touch now",
    },
    {
      "sectionType": "topSeparator",
      "secondary": true,
    },
    {
      "sectionType": "teaser",
      "img": "https://s3-eu-west-1.amazonaws.com/hibase-static-assets/pictures/ws/tsr6.png",
      "direction": "rtl",
      "title": "Empower marketing and user research",
      "subtitle": "Use your Data Warehouse as a backend for your marketing landing pages and customer surveys. Prototype rapidly and get the data right where you want it to be.",
      "cta": "Get in touch now",
    },
    {
      "sectionType": "bottomSeparator",
    },
    {
      "sectionType": "teaser",
      "img": "https://s3-eu-west-1.amazonaws.com/hibase-static-assets/pictures/ws/tsr7.png",
      "secondary": true,
      "direction": "ltr",
      "title": "Leverage your data in new ways",
      "subtitle": "Close the loop and get the most out of your data by integrating your Data Warehouse within your product, sales and operations.",
      "cta": "Get in touch now",
    },
    {
      "sectionType": "bottomSeparator",
      "secondary": true,
    },
  ]
}