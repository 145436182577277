const styles = theme => ({
  footer: {
    display: 'flex',
    padding: '100px',
    minHeight: '150px',
    flex: 1,
    // backgroundColor: theme.palette.primary[200],
    backgroundColor: theme.palette.brand.background,
  },
  footerMobile: {
    padding: '100px 0px'
  },
  footerRow: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  footerColumn: {
    maxWidth: '300px',
  },
  footerColumnHeader: {
    fontWeight: 'bold',
  },
  footerItem: {
    hover: 'none',
  },
  footerDialog: {
    minWidth: '300px',
    width: '100%',
    maxWidth: '800px',
  },
  formLegalNoticeText: {
    ...theme.typography.caption,
    textDecoration: 'none',
  },
  footerLogo: {
    opacity: '0.8',
  },
});

export default styles;
