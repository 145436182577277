const styles = theme => ({
  firstSlide: {
  	paddingTop: '2.5rem',
  },
  secondarySlide: {
  	backgroundColor: theme.palette.layout.secondaryBackground,
  },
});

export default styles;
