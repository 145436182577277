export function fireGtmEventFunc(event, payload) {
  const { uuid, firstSeen, debug, mobile, lpVariants, lpIndex, lpRevision, lpVariables,
        formVariant, formRevision, urlParams } = this.state;
  const lpVariant = lpVariants[lpIndex];
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: event,
    payload: { 
      uuid, 
      firstSeen, 
      debug, 
      mobile, 
      lpVariants, 
      lpIndex, 
      lpVariant, 
      lpVariables, 
      lpRevision,    
      formVariant, 
      formRevision, 
      urlParams, 
      ...payload
    },
  });
};