const styles = theme => ({
  navContainer: {
    position: 'sticky',
    height: 90,
    top: -16,
    zIndex: 10,
    background: 'white',
    "&:before": {
      top: 58,
      boxShadow: '0px 2px 15px rgba(0,0,0,0.2)',
      content: '""',
      display: 'block',
      height: 16,
      position: 'sticky',
    },
    "&:after": {
      content: '""',
      display: 'block',
      height: 16,
      position: 'sticky',
      background: 'white',
      top: 0,
      zIndex: 2,
    }
  },
  stickyNav: {
    width: '100%',
    background: 'white',
    height: 74,
    padding: 0,
    position: 'sticky',
    top: 0,
    marginTop: -16,
    zIndex: 3,
  },
  navBar: {
    paddingLeft: 0,
    paddingRight: 0,
  },

  navigationContainer: {
    top: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  mobileNavigationMenuButton: {
    marginRight: '15px',
  },
  mobileNavigationMenuIcon: {
    height: '34px',
    width: '34px',
  },
  mobileNavigationMenuList: {
    marginTop: '50px',
  },
  navigationButton: {
    marginRight: '15px',
    backgroundColor: 'rgba(0,0,0,0.27)',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.87)',
    }
  },
  navLink: {
    ...theme.typography.body1,
    '&:hover': {
      color: `${theme.palette.primary[500]} !important`,
    }
  },
  cta: {
    marginLeft: 15,
  },
});

export default styles;
