import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles.js';

import { GTM_EVENTS, ERROR_FALLBACK_ENDPOINT } from 'appConstants';

import apiRequest, { synchronousRequest } from 'apiRequest';
import { calculateFormState } from 'gistData';

import Beforeunload from 'react-beforeunload';

import Footer from 'components/Footer';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

class SurveyForm extends React.PureComponent {
  fireGtmEvent = this.props.fireGtmEvent;

  commonFields = () => {
    const { state, stateOverride } = this.props;
    const { uuid, debug, mobile, firstSeen, formCompleted, lpVariants, lpIndex, urlParams } = { ...state, ...stateOverride };

    return {
      uuid,
      first_seen: firstSeen,
      url_params: urlParams,
      form_completed: formCompleted,
      debug,
      mobile,
      lp_variants: lpVariants && lpVariants.join(', '),
      lp_variant: lpVariants && lpVariants[lpIndex],
    };
  }

  fallbackOnError = (requestBody, errorResponse) => {
    if(ERROR_FALLBACK_ENDPOINT) {
      const options = {
        method: 'POST',
        body: {
          ...this.commonFields(),
          ...errorResponse ? { "status": errorResponse.status } : {},
          "body": JSON.stringify(requestBody),
        },
      };
      return apiRequest(ERROR_FALLBACK_ENDPOINT, options).then(response => {});
    }
  }

  postForm = (silent=false) => {
    const { state, stateOverride, formTemplate } = this.props;
    const { formFields, formCompleted } = { ...state, ...stateOverride };

    if(Object.values(formFields).length > 0 && !formCompleted) {
      localStorage.setItem('formFieldsState', JSON.stringify(formFields));
      const apiEndpoint = formTemplate.endpoint;

      const formAttributes = [].concat.apply([], formTemplate.fields || [[]])
                                  .map(field => field.attribute)
                                  .filter(f => !!f)
                                  .reduce((obj, key) => {
                                      obj[key] = formFields[key];
                                      return obj;
                                    }, {});

      const mergedFields = {
        ...this.commonFields(),
        ...formAttributes,
        ...!silent ? { form_completed: true } : {},
      };

      const omitAttributes = formTemplate.omitAttributes || [];

      const filteredAttributes = Object.keys(mergedFields).filter(f => !omitAttributes.includes(f))
                                  .reduce((obj, key) => {
                                      obj[key] = mergedFields[key];
                                      return obj;
                                    }, {});

      const options = {
        method: 'POST',
        body: filteredAttributes,
      };

      if(silent) {
        return synchronousRequest(apiEndpoint, options);
      } else {
        return apiRequest(apiEndpoint, options)
          .then(response => {
            if(!response.data && response.status !== 200) this.fallbackOnError(formFields, response);
            this.props.onFormSubmit(response);
          }).catch(err => {
            this.fallbackOnError(options.body);
            this.props.onFormSubmit(err)
          });
      }
    }
  };

  changeFormStep = (delta) => {
    const { state, stateOverride, setState, formTemplate } = this.props;
    const { currentFormStep } = { ...state, ...stateOverride };

    const newStep = currentFormStep + delta;

    const newState = {
      currentFormStep: newStep,
      ...calculateFormState(formTemplate, newStep, { ...state, ...stateOverride }),
    };

    setState(newState);
    const containerDiv = document.getElementById('formContentContainer');
    containerDiv.scrollTop = 0;
    this.fireGtmEvent(GTM_EVENTS.changedFormStep, {...state, ...newState});
  };

  formFields = () => {
    const { state, stateOverride, formTemplate, renderForm, onChange, onBlur, onFocus } = this.props;
    const { currentFormStep } = { ...state, ...stateOverride };

    const propsOverride = { ...onChange && onChange, ...onBlur && onBlur, ...onFocus && onFocus };

    return (
      <div>
        { renderForm(formTemplate, propsOverride)[currentFormStep].map((field, i) => (
          { ...field, key: `formField${i}` }
        )) }
      </div>
    );
  };

  render() {
    const { classes, state, stateOverride, successMessage, cta, open, onClose, requiredFieldsFilled,
            postBeforeUnload, showSuccessDialog, title, mobile, formTemplate, withFooter, fireGtmEvent,
            renderHTML } = this.props;
    const { formSteps, currentFormStep, disableNextStep, atLastStep } = { ...state, ...stateOverride };

    const disableCta = disableNextStep || !requiredFieldsFilled();

    let renderTitle;
    if(formTemplate.title) {
      if(formTemplate.title.constructor === Array) {
        renderTitle = formTemplate.title[currentFormStep];
      } else {
        renderTitle = formTemplate.title;
      }
    }

    if(!renderTitle) renderTitle = title;

    return (
      <div>
        {postBeforeUnload &&
          <Beforeunload onBeforeunload={() => this.postForm(true)} />
        }
        <Dialog
          open={open}
          fullScreen={mobile}
          onClose={onClose}
          classes={{paper: `${classes.formContainer} ${mobile ? classes.formContainerMobile : ''}`}}>
          <DialogTitle disableTypography className={classes.dialogTitle}>
            <h2>{renderTitle}</h2>
            { mobile &&
              <IconButton color="inherit" onClick={onClose} aria-label="Close">
                <CloseIcon />
              </IconButton>
            }
          </DialogTitle>
          <DialogContent id="formContentContainer">
            { this.formFields() }
          </DialogContent>
          { atLastStep &&
            <div className={classes.formLegalNoticeSubmit}>
              <span className={classes.formLegalNoticeText}>By clicking "{ formTemplate.submitCta || cta }" you agree to our </span>
              <Footer
                only={"privacy"}
                renderHTML={renderHTML}
                fireGtmEvent={fireGtmEvent} />
            </div>
          }
          { atLastStep &&
            <DialogActions>
              <Button
                className={classes.dialogButton}
                disabled={disableCta}
                onClick={() => this.postForm()}>
                { formTemplate.submitCta || cta }
              </Button>
            </DialogActions>
          }
          { withFooter && currentFormStep === 0 &&
            <div className={classes.formLegalNotice}>
              <Footer
                asLinks
                renderHTML={renderHTML}
                fireGtmEvent={fireGtmEvent} />
            </div>
          }
          { formSteps > 1 &&
            <MobileStepper
              className={classes.stepper}
              type="dots"
              steps={formSteps}
              position="static"
              activeStep={currentFormStep}
              nextButton={
                <Button size={"small"} onClick={() => this.changeFormStep(1)}
                  disabled={atLastStep || disableNextStep}>
                  Next
                  <KeyboardArrowRight />
                </Button>
              }
              backButton={
                <Button size={"small"} onClick={() => this.changeFormStep(-1)} disabled={currentFormStep === 0}>
                  <KeyboardArrowLeft />
                  Back
                </Button>
              }
            />
          }
        </Dialog>

        <Dialog
          open={showSuccessDialog}
          onClose={this.props.onSuccessClose}
          classes={{paper: classes.formContainer}}>
          <DialogTitle disableTypography className={classes.dialogTitle}>
            <h2>Thank you</h2>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              { successMessage }
            </DialogContentText>
          </DialogContent>
          { atLastStep &&
            <DialogActions>
              <Button
                className={classes.dialogButton}
                onClick={this.props.onSuccessClose}>
                {"Close"}
              </Button>
            </DialogActions>
          }
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(SurveyForm);