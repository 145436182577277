import React from 'react';
import { GTM_EVENTS } from 'appConstants';
import footerContent from './footerContent';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles.js';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import Button from '@material-ui/core/Button';

class Footer extends React.PureComponent {
  initialState = {
    imprintOpen: false,
    privacyOpen: false,
    tcsOpen: false,
  };

  state = this.initialState;

  closeDialog = (dialogKey) => {
    this.setState(this.initialState, () => this.props.fireGtmEvent(GTM_EVENTS.closedFooterDialog, { dialogKey }))
  };

  openDialog = (dialogKey) => {
    this.setState({
      ...this.initialState,
      ...{[`${dialogKey}Open`]: true},
    }, () => this.props.fireGtmEvent(GTM_EVENTS.openedFooterDialog, { dialogKey }))
  };

  makeDialog = (key, title, content) => {
    const { classes } = this.props;

    return (
      <Dialog
        open={this.state[`${key}Open`]}
        onClose={() => this.closeDialog(key)}
        classes={{paper: classes.footerDialog}}>
        <DialogTitle disableTypography>
          <h2>{title}</h2>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            { content }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={`${classes.secondaryCta} ${classes.dialogCta}  ${classes.ctaSecondary}`}
            onClick={this.closeDialog}>
            {"Close"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const { mobile, classes, onOpenContactForm, asLinks, only } = this.props;

    const footerHTMLContent = footerContent(classes);

    const copyrightText = `© ${(new Date()).getFullYear()} hibase UG`;

    if(asLinks || only) {
      return (
        <span>
          {(!only || only === 'imprint') && <a className={classes.formLegalNoticeText} href="#" onClick={() => this.openDialog('imprint')}>Legal notice</a>}
          {!only && <span className={classes.formLegalNoticeText}> | </span>}
          {(!only || only === 'privacy') && <a className={classes.formLegalNoticeText} href="#" onClick={() => this.openDialog('privacy')}>Privacy policy</a>}
          {!only && <span className={classes.formLegalNoticeText}> | </span>}
          {!only && <span className={classes.formLegalNoticeText}>{copyrightText}</span>}
          { this.makeDialog('imprint', 'Legal notice', footerHTMLContent.imprint) }
          { this.makeDialog('privacy', 'Privacy policy', footerHTMLContent.privacy) }
        </span>
      );
    } else {
      return (
        <div className={`${classes.footer} ${mobile ? classes.footerMobile : ''}`}>
          <div className={classes.footerRow}>
            <List dense className={classes.footerColumn}>
              <ListItem>
                <ListItemText 
                  disableTypography
                  className={classes.footerColumnHeader}
                  primary="About hibase"
                />
              </ListItem>
              <ListItem button className={classes.footerItem} onClick={() => onOpenContactForm(null, 'footer')}>
                <ListItemText
                  primary="Contact us"
                />
              </ListItem>
              <ListItem button className={classes.footerItem} onClick={() => this.openDialog('imprint')}>
                <ListItemText
                  primary="Legal notice"
                />
              </ListItem>

              <ListItem button className={classes.footerItem} onClick={() => this.openDialog('privacy')}>
                <ListItemText
                  primary="Privacy policy"
                />
              </ListItem>

              <ListItem className={classes.footerItem}>
                <ListItemText
                  primary={copyrightText}
                />
              </ListItem>
            </List>

            <List dense className={classes.footerColumn}>
              <ListItem>
                <img src="https://s3-eu-west-1.amazonaws.com/hibase-static-assets/logo.png" height="30" alt="hibase" className={classes.footerLogo} />
              </ListItem>
              <ListItem>
                <ListItemText 
                  primary="Made with 🧡 in Berlin"
                />
              </ListItem>
              <ListItem>
                <ListItemText 
                  primary="info@hibase.co"
                />
              </ListItem>
            </List>
          </div>
          { this.makeDialog('imprint', 'Legal notice', footerHTMLContent.imprint) }
          { this.makeDialog('privacy', 'Privacy policy', footerHTMLContent.privacy) }
        </div>
      );
    }
  }
}

export default withStyles(styles)(Footer);
