import React from 'react';

import lp1 from 'defaultTemplates/lp1';
import mp1 from 'defaultTemplates/mp1';
import rt1 from 'defaultTemplates/rt1';
import pp1 from 'defaultTemplates/pp1';
import cc1 from 'defaultTemplates/cc1';

import Teaser from 'components/DesignBlocks/Teaser';
import FeatureHero from 'components/DesignBlocks/FeatureHero';
import Pricing from 'components/DesignBlocks/Pricing';

import logoIcon from 'assets/logo.svg'

export function renderIcon(iconLabel) {
  const iconsMap = {
    logo: logoIcon,
  };

  return iconsMap[iconLabel] || '';
};

export function renderTemplate(template) {
  const { width, classes } = this.props;
  const mobile = width === 'xs' || width === 's';

  const { cta: globalCta, formCompletedCta } = { ...lpTemplates.default.globals, ...template.globals || {} };

  const commonProps = {
    // classes: classes, 
    mobile: mobile,
    cta: globalCta,
    renderHTML: this.renderHTML,
    ...!this.state.formCompleted && { onClick: this.openForm },
    ...this.state.formCompleted && { onClick: this.openContactForm },
  };

  const parsedSections = [].concat.apply([], [
    ...template.sections.map(section => {
      if(section.sectionType === 'diagonalSeparator') {
        return [ 
          {...section, sectionType: 'diagonalSeparatorTop' },
          {...section, sectionType: 'diagonalSeparatorSpacer' },
          {...section, sectionType: 'diagonalSeparatorBottom' },
        ]
      } else if(['oneTwoThree', 'smallTeaser'].includes(section.sectionType)) {
        return [ 
          { ...section },
          { "sectionType": "diagonalSeparatorBottom" },
        ]
      } else if(section.sectionType === 'taggedText') {
        return [ 
          { sectionType: 'diagonalSeparatorTop' },
          { ...section },
          { "sectionType": "diagonalSeparatorBottom" },
        ]
      } else {
        return section;
      }
    })
  ]);

  let lpSlideIndex = 0;
  let navigationIndex = 0;
  const idTracking = (navigationLabel) => ({
    id: `lpSlide${lpSlideIndex += 1}`,
    className: `lpSection ${!!navigationLabel ? 'navigationAnchor' + (navigationIndex += 1).toString() : ''}`,
  });

  return parsedSections.map((section, i) => {
    const { sectionType, navigationLabel, ...sectionProps } = section;
    const key = `lpSection${i}`;

    const finalProps = { 
      ...commonProps, 
      ...sectionProps,
      ...this.state.formCompleted ? { cta: formCompletedCta } : {},
    };

    switch(sectionType) {
      case 'teaser':
        return <div key={key} {...idTracking(navigationLabel)}><Teaser {...finalProps} lpSlideIndex={lpSlideIndex} /></div>;
      case 'featureHero':
        return <div key={key} {...idTracking(navigationLabel)}><FeatureHero {...finalProps} lpSlideIndex={lpSlideIndex} /></div>;
      case 'pricing':
        return <div key={key} {...idTracking(navigationLabel)}><Pricing {...finalProps} lpSlideIndex={lpSlideIndex} /></div>;
      case 'topSeparator':
        return <div key={key} className={`${sectionProps.secondary ? classes.topSeparatorS : classes.topSeparatorP} ${sectionProps.reverse ? classes.reverse : ''}`} />;
      case 'bottomSeparator':
        return <div key={key} className={`${sectionProps.secondary ? classes.bottomSeparatorS : classes.bottomSeparatorP}`} />;
      
      default:
        return null;
    }
  })
};

const lpTemplates = {
  defaultVariables: {
    // "price1_testing1": "1.449€ Anschluss",
    // "price1_coaching1": "99€ pro Monat"
  },
  default: [ lp1, mp1, rt1, cc1 ]
};

export default lpTemplates;