import React from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';

function getCommonProps(attribute, label) {
  const { classes } = this.props;

  return {
    attribute,
    label,
    id: `fieldAttribute-${attribute}`,
    value: (this.state && this.state.formFields[attribute]) || '',
    onChange: (evt) => this.onChange(attribute, evt.target.value),
    className: `${classes.formField}`,
    onFocus: (evt) => this.onFormFieldFocus(attribute),
    onBlur: (evt) => this.onFormFieldBlur(attribute),
  }
}

export function DescriptionFieldTemplate(value) {
  const { classes } = this.props;
  return (
    <div className={classes.formDescriptionField}>
      { this.renderHTML(value) }
    </div>
  );
};

export function TextFieldTemplate(attribute, label, fieldProps={}) {
  const { classes } = this.props;

  return (
    <div className={classes.formRow}>
      <TextField
        {...getCommonProps.bind(this)(attribute, label)}
        {...fieldProps} />
    </div>
  );
};

export function SelectFieldTemplate(attribute, label, options, fieldProps={}) {
  const { classes } = this.props;

  return (
    <div className={classes.formRow}>
      <TextField
        select
        {...getCommonProps.bind(this)(attribute, label)}
        {...fieldProps}
      >
        {
          options.map((option, i) => {
            return (
              <MenuItem key={`${attribute}SelectField${i}${option.value}`} value={option.value || ''}>
                { this.renderHTML(option.label) }
              </MenuItem>
            );
          })
        }
      </TextField>
    </div>
  );
};

export function RadioFieldTemplate(attribute, label, options, fieldProps={}) {
  const { classes } = this.props;

  const { required, className, InputLabelProps, InputProps, ...otherProps } = { ...getCommonProps.bind(this)(attribute, label), ...fieldProps };

  return (
    <div className={classes.formRow}>
      <FormControl component="fieldset" required={required} className={className}>
        <FormLabel component="legend" className={InputLabelProps.classes.shrink}>{ this.interpolateParams(label) }</FormLabel>
        <RadioGroup
          aria-label={`${attribute}FormLabel`}
          name={`${attribute}FormName`}
          className={this.props.classes.radioGroup}
          {...otherProps}
        >
          {
            options.map((option, i) => {
              return (
                <FormControlLabel
                  key={`${attribute}RadioField${i}${option.value}`}
                  value={option.value || ''}
                  control={<Radio className={this.props.classes.radioGroupRadio} />}
                  label={ this.interpolateParams(option.label) } />
              );
            })
          }
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export function CheckFieldTemplate(attribute, label, options, fieldProps={}) {
  const { classes } = this.props;

  const { required, className, InputLabelProps, value, onFormFieldBlur, onFormFieldFocus } = { ...getCommonProps.bind(this)(attribute, label), ...fieldProps };

  const onChange = (evt, checked) => {
    let newValue = [ ...value || [] ];
    if(checked && newValue.indexOf(evt.target.value) === -1) newValue.push(evt.target.value);
    if(!checked && newValue.indexOf(evt.target.value) !== -1) newValue.splice(newValue.indexOf(evt.target.value), 1);

    this.onChange(attribute, newValue);
  }

  return (
    <div className={classes.formRow}>
      <FormControl component="fieldset" required={required} className={className}>
        <FormLabel component="legend" className={InputLabelProps.classes.shrink}>{ this.interpolateParams(label) }</FormLabel>
        <FormGroup
          className={this.props.classes.radioGroup}
          onFormFieldFocus={onFormFieldFocus}
          onFormFieldBlur={onFormFieldBlur}>
          {
            options.map((option, i) => {
              return (
                <FormControlLabel
                  key={`${attribute}RadioField${i}${option.value}`}
                  control={
                    <Checkbox
                      className={this.props.classes.radioGroupRadio}
                      checked={value.indexOf(option.value) !== -1}
                      onChange={onChange}
                      value={option.value}
                    />
                  }
                  label={ this.interpolateParams(option.label) } />
              );
            })
          }
        </FormGroup>
      </FormControl>
    </div>
  );
};