import React from 'react';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles.js';

class Pricing extends React.PureComponent {
  render() {
    const { 
      classes, 
      renderHTML, 
      title, 
      cta, 
      pricings,
      onClick, 
      lpSlideIndex,
    } = this.props;

    const containerClasses = `
      fdb-block 
      ${lpSlideIndex === 1 ? classes.firstSlide : ''}
      ${classes.container}
    `;

    return (
      <section className={containerClasses}>
        <div className={`${classes.blocksContainer} container`}>
          <div className="row text-center">
            <div className="col">
              <h1>{ renderHTML(title) }</h1>
            </div>
          </div>

          <div className="row mt-5 align-items-center">
            {
              pricings.map((pricing, i) => (
                <div className="col-12 col-sm-10 col-md-8 m-auto col-lg-4 text-center pt-4 pt-lg-0">
                  <div className={`fdb-box App-growOnHover ${pricing.large ? 'px-4 pt-5' : 'p-4'}`}>
                    <h2>{ renderHTML(pricing.title) }</h2>
                    <p className="lead">{ renderHTML(pricing.subtitle) }</p>

                    <p className="h1 mb-5 mt-5">{ renderHTML(pricing.price) }</p>

                    <p>
                      <Button
                        onClick={() => onClick(lpSlideIndex, 'pricingSlide')}>
                        { renderHTML(pricing.cta || cta) }
                      </Button>
                    </p>
                  </div>
                </div>
              ))
            }            
          </div>
        </div>
      </section>
    );
  }
}

export default withStyles(styles)(Pricing);
