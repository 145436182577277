import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles.js';

class Teaser extends React.PureComponent {
  render() {
    const { 
      classes, 
      renderHTML, 
      title, 
      subtitle, 
      snippets=[],
      // cta, 
      img,
      // direction='ltr', 
      secondary,
      // onClick, 
      lpSlideIndex,
    } = this.props;

    const containerClasses = `
      fdb-block 
      ${lpSlideIndex === 1 ? classes.firstSlide : ''}
      ${secondary ? classes.secondarySlide : ''}
    `;

    return (
      <section className={containerClasses}>
        <div className="container">
          <div className="row justify-content-center pb-xl-5">
            <div className="col-12 col-md-8 text-center">
              <h1>{ renderHTML(title) }</h1>
              <p className="lead">{ renderHTML(subtitle) }</p>
            </div>
          </div>

          <div className="row text-right align-items-center-lg align-items-end pt-5">
            <div className="col-7 col-md-5 col-lg-6 m-auto mb-md-0 mt-md-0 m-lg-auto">
              <img alt="hibase" className="img-fluid App-growOnHover" src={img} />
            </div>

            <div className="col-12 col-md-7 col-lg-6 col-xl-5 m-auto text-left pt-5 pt-md-0">
              {
                snippets.map((snippet, i) => {
                  return (
                    <div key={`slide${lpSlideIndex}Snippet${i}`}>
                      <h3><strong>{ renderHTML(snippet.title) }</strong></h3>
                      <p>{ renderHTML(snippet.subtitle) }</p>
                    </div>
                  );
                })
              }
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withStyles(styles)(Teaser);
