import {
  TextFieldTemplate,
  SelectFieldTemplate,
  DescriptionFieldTemplate,
  RadioFieldTemplate,
  CheckFieldTemplate,
} from './FormFieldTemplates';
import { CONTACT_FORM_ENDPOINT } from 'appConstants';

import ff1 from 'defaultTemplates/ff1';

export default {
  default: ff1,
  contactForm: {
    "endpoint": CONTACT_FORM_ENDPOINT,
    "omitAttributes": [],
    "fields": [
      [
        {
          "fieldType": "text",
          "attribute": "name",
          "label": "Your Name",
          "required": true,
          "autoFocus": true,
        },
        {
          "fieldType": "text",
          "attribute": "email",
          "label": "Your Email",
          "required": true,
        },
        {
          "fieldType": "text",
          "attribute": "message",
          "label": "How can we help you?",
          "multiline": true,
          "rows": 4,
          "required": true,
          "autoFocus": true,
        }
      ]
    ]
  }
}

export function renderForm(formTemplate, propsOverride = {}) {
  const TextField = TextFieldTemplate.bind(this);
  const SelectField = SelectFieldTemplate.bind(this);
  const DescriptionField = DescriptionFieldTemplate.bind(this);
  const RadioField = RadioFieldTemplate.bind(this);
  const CheckField = CheckFieldTemplate.bind(this);

  return formTemplate.fields.map(pageFields => {
    return pageFields.map(field => {
      const { fieldType, attribute, label, value, options, ...fieldProps } = field;
      if(fieldType === 'description') {
        return DescriptionField(value);
      } else if(fieldType === 'text') {
        return TextField(attribute, label, {...propsOverride, ...fieldProps});
      } else if (fieldType === 'select') {
        return SelectField(attribute, label, options, {...propsOverride, ...fieldProps});
      } else if (fieldType === 'radio') {
        return RadioField(attribute, label, options, {...propsOverride, ...fieldProps});
      } else if (fieldType === 'check') {
        return CheckField(attribute, label, options, {...propsOverride, ...fieldProps});
      } else {
        return null;
      }
    })
  })
};