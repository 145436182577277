export default {
  "endpoint": "signups",
  "contactEndpoint": "contact",
  "submitCta": "Submit",
  "title": [
    // One per page in the form
    "Get in touch"
  ], 
  "fields": [
    [
      {
        "fieldType": "description",
        "value": "Let's discuss the best data strategy for your company."
      },
      {
        "fieldType": "text",
        "attribute": "name",
        "label": "Your name",
        "required": true,
        "autoFocus": true,
      },
      {
        "fieldType": "text",
        "attribute": "email",
        "label": "Your work email",
        "required": true,
      },
      {
        "fieldType": "select",
        "attribute": "role",
        "label": "Your role",
        "required": false,
        "options": [
          { "label": "Data Analyst", "value": "data_analyst" },
          { "label": "BI Manager", "value": "bi_manager" },
          { "label": "IT Manager / CTO", "value": "it_manager" },
          { "label": "Product Manager / CPO", "value": "product_manager" },
        ]
      },
    ],
  ]
}