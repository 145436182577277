export default {
  "navigationLabel": "Data automation",
  "slug": "data-automation",
  "globals": {
    // "formTitle": "Hi",
    "cta": "Get in touch",
    "formCompletedCta": "Contact us",
    "successMessage": "Thank you",
  },

  "sections": [
    {
      "sectionType": "featureHero",
      "img": "https://s3-eu-west-1.amazonaws.com/hibase-static-assets/pictures/ws/tsr4.png",
      "backgroundColor": "#2a373e",
      "direction": "ltr",
      "title": "Integrate with anything",
      "subtitle": "A consistent framework to move large amounts of data from any source to your data warehouse. Without having to write code.",
      "cta": "Get in touch now",
      "snippets": [
        {
          "title": "No-magic integration",
          "subtitle": "Source data from any service through a configurable tool that handles complexity for you without compromising on flexibility and transparency."
        },
        {
          "title": "Solid scheduling and automation",
          "subtitle": "Automate work and easily set dependencies to reliably source data according to your business logic."
        },
        {
          "title": "Composable and programmable",
          "subtitle": "Program your workflows through a simple syntax that lets you reuse and glue data across sources. Avoid repeating yourself by saving and reusing shared logic."
        },
      ]
    },
    {
      "sectionType": "bottomSeparator",
    },
    {
      "sectionType": "teaser",
      "img": "https://s3-eu-west-1.amazonaws.com/hibase-static-assets/pictures/ws/sc2.png",
      "secondary": true,
      "direction": "ltr",
      "title": "Stay on top of your game",
      "subtitle": "Retrieve the data you need, whenever you need it. Setup, monitor and control your workflows in an intuitive and consistent interface, without ever having to ask IT again.",
      "cta": "Get in touch now",
    },
    {
      "sectionType": "topSeparator",
      "secondary": true,
    },
    {
      "sectionType": "teaser",
      "img": "https://s3-eu-west-1.amazonaws.com/hibase-static-assets/pictures/ws/sc1.png",
      "direction": "rtl",
      "title": "Be smart and efficient",
      "subtitle": "A consistent framework together with a powerful syntax lets you query only what you need, for faster and more frequent ingestion of new data.",
      "cta": "Get in touch now",
    },
    {
      "sectionType": "bottomSeparator",
    },
    {
      "sectionType": "teaser",
      "img": "https://s3-eu-west-1.amazonaws.com/hibase-static-assets/pictures/ws/sc3.png",
      "direction": "ltr",
      "secondary": true,
      "title": "Improve flexibility",
      "subtitle": "Let schema updates happen automatically and perform incremental updates of your data based on rules you define.",
      "cta": "Get in touch now",
    },
    {
      "sectionType": "bottomSeparator",
      "secondary": true,
    },
  ]
}