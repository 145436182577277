import React from 'react';
import { hydrate, render } from "react-dom";
import 'index.css';
import App from 'App';
import registerServiceWorker from 'registerServiceWorker';
import 'typeface-roboto';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import muiTheme from 'theme';

const theme = createMuiTheme(muiTheme);

function ThemedApp() {
  return (
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  );
}

window.snapSaveState = () => {
  Array.from(document.querySelectorAll('[data-jss]')).forEach(elem =>
    elem.setAttribute('data-jss-snap', ''),
  );
};

const removeStaticStyles = () => {
  Array.from(document.querySelectorAll('[data-jss-snap]')).forEach(el => {
  	const parent = el.parentNode;
  	if(parent) parent.removeChild(el);
  });
};

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<ThemedApp />, rootElement, removeStaticStyles);
} else {
  render(<ThemedApp />, rootElement, removeStaticStyles);
}
registerServiceWorker();