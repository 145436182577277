import React from 'react';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles.js';

class Teaser extends React.PureComponent {
  render() {
    const { 
      classes, 
      renderHTML, 
      title, 
      subtitle, 
      cta, 
      img,
      direction='ltr', 
      secondary,
      onClick, 
      lpSlideIndex,
    } = this.props;

    const renderText = (
      <div className={`${classes.heroBlock} col-12 col-md-5 col-lg-5`}>
        <h1>{ renderHTML(title) }</h1>
        <p className="lead">{ renderHTML(subtitle) }</p>
        {cta &&
          <p className="mt-4">
            <Button
              className={classes.cta}
              onClick={() => onClick(lpSlideIndex, 'heroSlide')}>
              { renderHTML(cta) }
            </Button>
          </p>
        }
      </div>
    );

    const renderImg = (
      <div className={`${classes.heroBlock} col-12 col-md-7 m-auto m-md-0 ml-md-auto pt-5`}>
        <p>
          <img alt="hibase" className="img-fluid App-growOnHover" src={img} />
        </p>
      </div>
    );

    const renderOrder = direction === 'rtl' ? [renderImg, renderText] : [renderText, renderImg];

    const containerClasses = `
      fdb-block 
      ${lpSlideIndex === 1 ? classes.firstSlide : ''}
      ${secondary ? classes.secondarySlide : ''}
    `;

    return (
      <section className={containerClasses} 
        data-block-type="call_to_action">
        <div className="container">
          <div className="row align-items-center pt-2 pt-lg-5">
            {renderOrder[0]}
            {renderOrder[1]}
          </div>
        </div>
      </section>
    );
  }
}

export default withStyles(styles)(Teaser);
