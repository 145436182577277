export default {
  "navigationLabel": "Consulting",
  "slug": "consulting",
  "globals": {
    // "formTitle": "Hi",
    "cta": "Get in touch",
    "formCompletedCta": "Contact us",
    "successMessage": "thank you",
  },

  "sections": [
    {
      "sectionType": "featureHero",
      "img": "https://s3-eu-west-1.amazonaws.com/hibase-static-assets/pictures/ws/tsr8.png",
      "backgroundColor": "#2a373e",
      "title": "Let's rethink data in your company",
      "subtitle": "We can help you make the most out of your data at all levels, from technical architecture to process redesign.",
      "snippets": [
        {
          "title": "Start off on a solid foundation",
          "subtitle": "Inadequate data setups inevitably come to a breaking point. We can help you fix your stack if you have one or get it right from the start, with tangible results from day one."
        },
        {
          "title": "Speed up your time-to-action",
          "subtitle": "In order to stay successful, it is crucial to get a grip on more data, more frequently. We can help you design processes that deliver just that, according to your specific needs."
        },
        {
          "title": "Empower your data team",
          "subtitle": "With little training, hibase can get your data team onto a whole new level. Let us work on your new data stack together and transfer our knowledge to your team for future-proof results."
        },
      ]
    },
    {
      "sectionType": "bottomSeparator",
    },
    {
      "sectionType": "teaser",
      "secondary": true,
      "img": "https://s3-eu-west-1.amazonaws.com/hibase-static-assets/pictures/ws/tsr10.png",
      "direction": "ltr",
      "title": "A first-in-class team at your service",
      "subtitle": "Benefit from a guided approach when (re)thinking your data infrastructure. We provide industry experts who can give you the right knowledge and tools for the job.",
      "cta": "Get in touch now",
    },
    {
      "sectionType": "topSeparator",
      "secondary": true,
    },
    {
      "sectionType": "teaser",
      "img": "https://s3-eu-west-1.amazonaws.com/hibase-static-assets/pictures/ws/tsr9.png",
      "direction": "rtl",
      "title": "A data platform you can rely on",
      "subtitle": "hibase is a battle tested platform that removes complexity and redundancy, ensuring your team can stay productive as your business grows.",
      "cta": "Get in touch now",
    },
    {
      "sectionType": "bottomSeparator",
    },
    {
      "sectionType": "teaser",
      "secondary": true,
      "img": "https://s3-eu-west-1.amazonaws.com/hibase-static-assets/pictures/ws/tsr11.png",
      "direction": "ltr",
      "title": "Game changing for your team",
      "subtitle": "hibase is designed to take over engineering from your data infrastructure. Free up your data team: learn it once, use it anywhere.",
      "cta": "Get in touch now",
    },
    {
      "sectionType": "bottomSeparator",
      "secondary": true
    },
  ]
}