// const baseCopyStyle = (theme) => {
//   return {
//     fontWeight: 300,
//     fontFamily: '"Helvetica", "Arial", sans-serif',
//     color: theme.palette.primary[700],
//   }
// };

const diagonalSeparator = (height, position, shapes) => {
  const renderShapes = shapes.map(shape => {
    return "<polygon fill='%23" + shape.color.substring(1) + "' points='100 0, 0 100, " + (shape.endCoordinates || '100 100') + "'/>"
  }).join('')

  // return "url(\"data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 100 100\' preserveAspectRatio=\'none\'>" + renderShapes + "</svg>\")"

  return {
    width: '100%',
    height: `${height}px`,
    ...position === 'bottom' ? {zIndex: 5, marginBottom: `-${height - 1}px`} : {},
    ...position === 'top' ? {zIndex: 5, marginTop: `-${height - 1}px`, transform: "scale(-1, 1)"} : {},
    backgroundImage: "url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none'>" + renderShapes + "</svg>\")",
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
  }
};

const styles = theme => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  loadingContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.primary[50],
    justifyContent: 'center',
  },

  formRow: {
    flex: 1,
    marginBottom: '18px',
  },
  formField: {
    width: '100%',
    // marginBottom: '16px',
    // minHeight: '48px',
  },
  formDescriptionField: {
    marginBottom: 25,
  },
  radioGroup: {
    margin: '8px 0px 0px 8px',
  },
  radioGroupRadio: {
    height: '40px',
  },

  // Diagonal separators
  topSeparatorP: diagonalSeparator(50, 'top', [
    {color: theme.palette.layout.primaryBackground, endCoordinates: '0 0'},
    {color: theme.palette.primary[200], endCoordinates: '100 20'},
    {color: theme.palette.primary[50], endCoordinates: '100 10'},
  ]),
  topSeparatorS: diagonalSeparator(50, 'top', [
    {color: theme.palette.layout.secondaryBackground, endCoordinates: '0 0'},
    {color: theme.palette.layout.primaryBackground, endCoordinates: '100 100'},
    {color: theme.palette.primary[200], endCoordinates: '100 20'},
    {color: theme.palette.primary[50], endCoordinates: '100 10'},

  ]),
  bottomSeparatorP: diagonalSeparator(50, 'bottom', [
    // {color: theme.palette.primary[50], endCoordinates: '0 0'},
    {color: theme.palette.layout.primaryBackground, endCoordinates: '0 0'},
    {color: theme.palette.primary[50], endCoordinates: '100 20'},
    {color: theme.palette.primary[200], endCoordinates: '100 10'},
  ]),
  bottomSeparatorS: diagonalSeparator(50, 'bottom', [
    // {color: theme.palette.primary[50], endCoordinates: '0 0'},
    {color: theme.palette.layout.secondaryBackground, endCoordinates: '0 0'},
    {color: theme.palette.primary[50], endCoordinates: '100 20'},
    {color: theme.palette.primary[200], endCoordinates: '100 10'},
  ]),
  diagonalSeparatorSpacer: {
    height: '20px',
    width: '100%',
    backgroundColor: theme.palette.primary[50],
  },
  reverse: {
    transform: 'rotateY(180deg)',
  },
  reverseX: {
    transform: 'rotateX(180deg)',
  },
});

export default styles;
