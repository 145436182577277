import 'whatwg-fetch';
import queryString from 'query-string';

import { API_FULL_URL, API_CONTENT_TYPE } from 'appConstants';

function parseJSON(response) {
	return response.text().then(function(text) {
    return text ? JSON.parse(text) : {}
  })
};

function parseOptions(options, endpointUrl) {
  // make a copy of options and remove the authenticate key
  let { authenticate, body, ...optionsCopy } = options;
  let authHeaders = {};

  const contentHeaders = {
    Accept: API_CONTENT_TYPE,
    'Content-Type': API_CONTENT_TYPE,
    'Host': API_FULL_URL,
  };

  const defaultHeaders = Object.assign(contentHeaders, authHeaders);

  optionsCopy.headers = Object.assign(defaultHeaders, optionsCopy.headers);
  let url = `${API_FULL_URL}/${endpointUrl}`;

  if (options.method === 'GET' && !!body) {
    optionsCopy.body = queryString.stringify(JSON.stringify(body));
    url = `${url}?${optionsCopy.body}`;
    delete optionsCopy.body;
  } else {
    optionsCopy.body = body ? JSON.stringify(body) : null;
  }

  return { parsedOptions: optionsCopy, url: url };
}

export function synchronousRequest(endpointUrl, options) {
	const { parsedOptions, url } = parseOptions(options, endpointUrl);
	
	let http = new XMLHttpRequest();
	http.open(parsedOptions.method, url, false);

	Object.keys(parsedOptions.headers).map(k => http.setRequestHeader(k, parsedOptions.headers[k]))

	http.send(parsedOptions.body);
}

export default function apiRequest(endpointUrl, options) {
  const { parsedOptions, url } = parseOptions(options, endpointUrl);

  return fetch(url, parsedOptions)
    .then(response => {
      return response.ok ? parseJSON(response) : response;
    });
}
