export default {
  "navigationLabel": "Home",
  "globals": {
    // "formTitle": "Hi",
    "cta": "Get in touch",
    "formCompletedCta": "Contact us",
    // "successMessage": "We will come back to you shortly",
  },

  "sections": [
    {
      "sectionType": "teaser",
      "img": "https://s3-eu-west-1.amazonaws.com/hibase-static-assets/pictures/ws/tsr1.png",
      "direction": "ltr",
      "title": "Do more, with less",
      "subtitle": "One data platform to empower data analysts and free up developer time",
      "cta": "Get in touch now",
    },
    {
      "sectionType": "bottomSeparator",
    },
    {
      "sectionType": "teaser",
      "img": "https://s3-eu-west-1.amazonaws.com/hibase-static-assets/pictures/ws/tsr2.png",
      "secondary": true,
      "direction": "rtl",
      "title": "Bring data from anywhere to where it matters",
      "subtitle": "Model your own business logic and processes in a well guided, battle tested framework",
      "cta": "Get in touch now",
    },
    {
      "sectionType": "topSeparator",
      "secondary": true,
    },
    {
      "sectionType": "teaser",
      "img": "https://s3-eu-west-1.amazonaws.com/hibase-static-assets/pictures/ws/tsr3.png",
      "direction": "ltr",
      "title": "We help you get data right, from the start",
      "subtitle": "Lets talk about a successful data strategy for your business. We have been there, we have done that",
      "cta": "Get in touch now",
    },
    {
      "sectionType": "bottomSeparator",
    },
  ]
}